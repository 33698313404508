import Axios from 'axios'
import { required } from "vuelidate/lib/validators";

export const ObraSocialServices = {
    //API
    api: {
        async find(id) {
            return Axios.get('obras-sociales/' + id)
                .then((response) => {
                    return response.data
                })
                .catch((error) => { throw error })
        },
        async getAll() {
            return Axios.get('obras-sociales')
                .then((response) => {
                    return response.data
                })
                .catch((error) => {
                    throw error
                })
        },
        async save(categoria) {
            return Axios.post('obras-sociales', categoria)
                .then((response) => {
                    return response.data
                })
                .catch((error) => {
                    throw error
                })
        },
        async put(id, categoria) {
            return Axios.put('obras-sociales/' + id, categoria)
                .then((response) => {
                    return response.data
                })
                .catch((error) => {
                    throw error
                })
        },
        async delete(categoria) {
            return Axios.delete('obras-sociales/' + categoria.id)
                .then((response) => {
                    return response.data
                })
                .catch((error) => {
                    throw error
                })
        },
    },
    //VALIDATIONS
    validations: {
        codem: { required },
        descripcion: { required },
    },
    //TABLELIST
    headers() {
        const header = [{ value: '#', sortable: true }, { value: 'Codem', sortable: true }, { value: 'Descripcion', sortable: true }]
        return header;
    },
    body() {
        const body = [{
                class: 'w-100',
                data: [{ value: 'id', class: 'text-primary', label: '#' }]
            },
            {
                class: 'important',
                data: [{ value: 'codem', label: "Codem" }]
            },
            {
                class: 'important',
                data: [{ value: 'descripcion', label: "Descripción" }]
            }
        ]
        return body
    },
    dataList() {
        return [
            { name: "descripcion", class: "" },
            { name: "codem", label: 'Codem', class: "text-secondary" }
        ]
    }

}
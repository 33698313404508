<template>
  <div class="modal-container">
  <div ref="modal-container"  class="modal-scroll">
    <div class="modal">
      <div class="modal-exit">
        <img src="@/assets/images/bottomsheet.svg" alt="" srcset="">
      </div>
      <div class="modal-exit-lg">
        <button @click="closeModal">
          <icon feather="x" />
        </button>
      </div>
      <slot name="modal-title"></slot>
      <slot name="modal-body"></slot>
    </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Modal",
  data() {
    return {
      app: document.querySelector(".grid-app"),
      overlay: document.createElement("div"),
    };
  },
  mounted() {
    this.createModal();
  },
  destroyed() {
    this.app.removeChild(this.overlay);
        document.querySelector('body').style.overflow = "auto"
  },
  methods: {
    createModal() {
      //Máscara
      document.querySelector('body').style.overflow = "hidden"
      this.overlay.classList.add("modal-overlay");
      this.app.appendChild(this.overlay);
      //Mostrar Modal
      let modal = this.$refs["modal-container"].querySelector(".modal");
      modal.classList.add("fade-in");
      setTimeout(() => {
        modal.classList.remove("fade-in");
      }, 400);
      //Listener close
      this.$refs["modal-container"].addEventListener("click", (e) => {
        if (this.$refs["modal-container"] == e.target) {
          this.closeModal();
        }
      });
    },
    closeModal() {
      let modal = this.$refs["modal-container"].querySelector(".modal");
      modal.classList.add("fade-out");
      setTimeout(() => {
        modal.classList.remove("fade-out");
        this.$emit("close")
        document.querySelector('body').style.overflow = "auto"
      }, 400);
    },
  },
};
</script>

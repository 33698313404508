<template>
  <div class="modal-form">
    <h3 class="card-title">Editar obra social</h3>
    <form @submit.prevent="save">
      <pd-input
        v-model="codem"
        id="codem"
        label="Codem"
        placeholder="Ingrese el codem"
        class="my-1"
        :required="enviado && !$v.codem.required"
      ></pd-input>
      <pd-input
        v-model="descripcion"
        id="descripcion"
        label="Descripcion"
        placeholder="Ingrese la descripcion"
        class="my-1"
        :required="enviado && !$v.descripcion.required"
      ></pd-input>
      <button class="btn btn-right btn-primary">Guardar</button>
    </form>
  </div>
</template>
<script>
import { ObraSocialServices } from "../services/ObraSocialServices";
import Swal from "sweetalert2";
export default {
  name: "EditarObraSocial",
  data() {
    return {
      codem: "",
      descripcion: "",
      enviado: false
    };
  },
  props: {
    obra_social: null,
  },
  validations:{
    ...ObraSocialServices.validations
  },
  mounted() {
    this.codem = ""+{...this.obra_social}.codem;
    this.descripcion = ""+{...this.obra_social}.descripcion;
  },
  methods: {
    save() {
      ObraSocialServices.api.put(this.obra_social.id,{
        descripcion: this.descripcion,
        codem: this.codem,
      })
        .then((r) => {
          Swal.fire(
            "Éxito",
            `<p><b>${r.obra_social.descripcion}</b> se ha guardado con éxito</p>`,
            "success"
          );
          this.$emit("saved");
        })
        .catch((e) => {
          Swal.fire("Error", `<p>${e}</p>`, "error");
        });
    },
  },
};
</script>
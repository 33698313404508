<template>
  <div class="card">
    <h3 class="card-title">Visualizar obra social</h3>
    <template v-if="obra_social">
      <h4 class="">
        <span class="text-secondary">Descripción:</span>
        {{ obra_social.descripcion }}
      </h4>
      <div class="d-flex space-between">
        <button
          class="btn btn-info"
          @click="$router.push({ name: 'ListarObrasSociales' })"
        >
          Volver
        </button>
        <div>
          <button class="btn btn-warning" @click="openEdit = true">
            Editar obra social
          </button>
          <button class="btn btn-error" @click="eliminarObraSocial">
            Eliminar obra social
          </button>
        </div>
      </div>
      <modal v-if="openEdit" @close="openEdit = false">
        <editar-obra-social
          slot="modal-body"
          :obra_social="obra_social"
          @saved="saved"
        ></editar-obra-social>
      </modal>
    </template>
  </div>
</template>
<script>
import { ObraSocialServices } from "../services/ObraSocialServices";
import Swal from "sweetalert2";
import Modal from "@/components/elements/Modal";
import EditarObraSocial from "./EditarObraSocial.vue";
export default {
  name: "VerObraSocial",
  components: {
    Modal,
    EditarObraSocial,
  },
  data() {
    return {
      obra_social: null,
      openEdit: false,
    };
  },
  mounted() {
    ObraSocialServices.api.find(this.$route.params.id).then((response) => {
      this.obra_social = response.obra_social;
    });
  },
  methods: {
    eliminarObraSocial() {
      Swal.fire({
        title: "Confirme",
        html: `<p>Está seguro que desea eliminar <b>${this.obra_social.descripcion}</b>?</p>`,
        icon: "question",
        showConfirmButton: true,
        confirmButtonText: "Eliminar",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          ObraSocialServices.api.delete(this.obra_social).then(() => {
            Swal.fire("Éxito", "<p>Se ha eliminado con éxito</p>", "success");
            this.$router.push({ name: "ListarObrasSociales" });
          });
        }
      });
    },
    saved() {
      this.openEdit = false;
      ObraSocialServices.api.find(this.obra_social.id).then((response) => {
        this.obra_social = response.obra_social;
      });
    },
  },
};
</script>